import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // Random room id
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  // Screen with room id
  {
    path: '/screen/:roomId',
    name: 'screen',
    component: () => import(/* webpackChunkName: "screen" */ '../views/ScreenView.vue')
  },

  // Remote with room id
  {
    path: '/remote/:roomId',
    name: 'remote',
    component: () => import(/* webpackChunkName: "remote" */ '../views/RemoteView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
