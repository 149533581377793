import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuesax from 'vuesax';

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.min.css'
import 'vuesax/dist/vuesax.css';
import './style.css';

Vue.use(Vuesax, {});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
